<template>
  <div class="main-contents info_mf code">
	<div class="tit">업무코드 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
	<div class="sub-tit"><p>업무코드 등록/수정</p></div>
	<div class="enter_inform">
		<div class="form">
			<div class="label">업무코드</div>
			<div class="list">
				<InputComp v-model="codeInfo.cdId" title="업무코드" :rules="{'required':true, 'maxLength':6, 'minLength':6}" v-bind:disabled="cdId != ''"/>
			</div>
		</div>
		<div class="form">
			<div class="label">업무코드명</div>
			<div class="list">
				<InputComp v-model="codeInfo.cdIdNm" maxByte="100" title="업무코드명" :rules="{'required':true}"/>
			</div>
		</div>
		<div class="form">
			<div class="label">업무코드 설명</div>
			<div class="list">
				<InputComp v-model="codeInfo.cdIdDesc" maxByte="300" title="업무코드 설명" />
			</div>
		</div>
		<div class="form">
			<div class="label">사용여부</div>
			<div class="list">
				<SelectComp v-model="codeInfo.useYn" list="Y:예,N:아니오"  title="사용여부" :rules="{'required':true}"/>
			</div>
		</div>
	</div>
		<div class="select_btn">
			<div class="btn_01" @click="movePage('back')">취소</div>
			<div class="btn_02" v-if="cdId == ''" @click="setBusinessCodeInfo()">등록</div>
			<div class="btn_02" style="background-color: #FF681D;" v-else @click="updateBusinessCodeInfo()">수정</div>
		</div>
  </div>
</template>

<script>
export default {
	data(){
		return{
			codeInfo : {
				cdId : '',
				cdIdNm : '',
				cdIdDesc : '',
				useYn : ''
			},
			
			cdId : this.$route.params.cdId || '',
		}
	},

	beforeMount() {
		// console.log('forward', this.$route.params);
		if(this.cdId != '') this.getBusinessCodeInfo();
	},

	methods : {
		getBusinessCodeInfo(){
			var param = {};
			param.cdId = this.cdId;

			this.$.httpPost('/api/main/adm/code/getBusinessCodeInfo', {cdId : this.cdId})
				.then(res => {
					this.codeInfo = res.data.codeInfo || {};
				}).catch(this.$.httpErrorCommon);
		},

		setBusinessCodeInfo(){
			this.$.popup('/adm/man/MAN931P01')
				.then(res=>{
					if(res){
					this.$.httpPost('/api/main/adm/code/setBusinessCodeInfo', this.codeInfo)
						.then(()=>{
							this.movePage();
						}).catch(this.$.httpErrorCommon);
					}
			})
		},

		updateBusinessCodeInfo(){
			this.$.popup('/adm/man/MAN931P01')
				.then(res=>{
					if(res){
					this.$.httpPost('/api/main/adm/code/updateBusinessCodeInfo', this.codeInfo)
						.then(()=>{
							this.movePage();
						}).catch(this.$.httpErrorCommon);
					}
				})
			},

		movePage(div) {
			if(div == 'back') this.$router.go(-1);
			else this.$router.push('MAN931M01');
		},
		}
	}
</script>

<style>

</style>